
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import qs from 'qs'
import Modal from "@/components/UI/Modal";
import { getCartInfo } from "@/api/useChangeShopCar";
import {inject} from "vue";
import router from "@/router";
const userInfo = inject("userInfo") as any;
const useShopCar = () => {

    //  后台存储
    function storgeBackstage(id: string, tid: number, qty: number, lotid: number, tcid: number,cartType: number,checkValue:number) {
        const guid = localStorage.getItem('guid')
        const data = qs.stringify({
            pid: id,
            tid,
            qty,
            lotid,
            tcid,
            cartType,
            checkValue,
            cartGUID: guid
        })
        axios.post('/M/Server/AddToCart', data)
            .then((res) => {
                if (res.data.success) {
                    if(res.data.shopCartGuid) {
                        localStorage.setItem('guid', res.data.shopCartGuid)
                    }
                    if(!guid || guid == 'null'){
                        localStorage.setItem('guid', res.data.shopCartGuid)
                    }
                    getCartInfo()
                    if(cartType == 1){
                        Toast({
                            type: "success",
                            title: "提交成功，可前往购物车查看",
                        });
                    }else{
                        Toast({
                            type: "success",
                            title: res.data.msg,
                        });
                    }
                } else {
                    Toast({
                        type: "error",
                        title: res.data.msg,
                    });
                }
            })
            .catch((err) => {
                console.log('err');
            });
    }
    //  前往登录
    function toLogin() {
        Modal({
            title: "温馨提示",
            content: '<div>登录后才可加购商品</div>',
            dangerouslyUseHTMLString: true,
            confirmText:"前往登录",
            onConfirm: () => {
                router.push({
                    name: "Login",
                    query: {
                        redirect: router.currentRoute.value.fullPath,
                    },
                });
            },
            onCancel: () => {
                console.log('取消')
            },
        });
    }

    function addShopCar(id: string, tid: number, qty: number, lotid: number, tcid: number, stock: number, nclassId: number,userId:string,checkValue: number) {
        // console.log("checkValue-",checkValue)
        if(!userId){
            toLogin();
        }else{
            //培养基或试剂配置-add20220224 考虑到产品都是有库存的，不需要这个条件，故注释，以后需要再重新写
            // if(tid ==7 || nclassId == 29){
            //     lotid =22;
            //     tcid =0;
            // }
            if ((typeof stock == 'number') && stock<1 && tid < 1) {//标物
                Modal({
                    title: "温馨提示",
                    // content: "库存不足，可能影响货期。建议更换其它同类产品，是否继续？",
                    content: '<div>库存不足，可能影响货期。<br/>建议更换其它同类产品，是否继续？</div>',
                    dangerouslyUseHTMLString: true,
                    onConfirm: () => {
                        storgeBackstage(id, tid, qty,lotid,tcid,0,checkValue)
                    },
                    onCancel: () => {
                        console.log('取消')
                    },
                });
            }else if((typeof stock == 'number') && stock<2 && tid > 0){//菌种细胞
                Modal({
                    title: "温馨提示",
                    content: '<div>库存不足，可能影响货期。<br/>如需订购请联系客服确认货期</div>',
                    dangerouslyUseHTMLString: true,
                    onConfirm: () => {
                        storgeBackstage(id, tid, qty,lotid,tcid,0,checkValue)
                    },
                    onCancel: () => {
                        console.log('取消')
                    },
                });
            }else {
                storgeBackstage(id, tid, qty,lotid,tcid,0,checkValue)
            }
        }
    }


    function updatecommodity(id: string, qty: number) {
        const guid = localStorage.getItem('guid')
        const data = qs.stringify({
            pid: id,
            qty: qty,
            cartGUID: guid
        })
        axios.post('/M/Server/UpdateCartItem', data).then((res) => {
            if (res.data.success) {
                // Toast({
                //     type: "success",
                //     title: res.data.msg,
                // });
                getCartInfo()
            } else {
                Toast({
                    type: "error",
                    title: res.data.msg,
                });
            }
        })
            .catch((err) => {
                console.log('err');
            });


    }
    function addStorge(id: string, qty: number) {

        updatecommodity(id, qty)

    }
    function subStorge(id: string, qty: number) {

        updatecommodity(id, qty)

    }


    return {
        addShopCar,
        addStorge,
        subStorge,
        storgeBackstage
    }

}

export default useShopCar
