
import {defineComponent, ref, isReactive, reactive, inject, watch} from "vue";
import api from "@/api/axios";
import Toast from "@/components/UI/Toast/";
import useShopCar from "@/hooks/useShopCar";
import {useRouter} from "vue-router";

export default defineComponent({
  props: {
    list: Object,
    name: String,
    tid: {
      type: String,
      default: "",
    },
    anclassId: {
      type: String,
      default: "",
    },
    typeFlag: {
      type: String,
      default: ""
    },
    upParam: {
      type: String,
      default: ""
    }
  },
  setup(props) {

    const userInfo = inject("userInfo") as any;

    // console.log("list",props.list);
    const biaowuPriceOpen = ref("1");
    // console.log('props.upParam',props.upParam);
    const router = useRouter();
    if(props.upParam =='0' || props.upParam =='1'){
      // console.log("111111111111");
      // eslint-disable-next-line vue/no-setup-props-destructure
      biaowuPriceOpen.value =props.upParam;
    }
    // console.log("biaowuPriceOpen1",biaowuPriceOpen);
    watch(
        () => {
          return props.list
        },
        (NewsVue) => {
          // console.log('NewsVue',NewsVue)
          // console.log('props.upParam',props.upParam)
          if(props.upParam){
            biaowuPriceOpen.value =props.upParam;
          }else{
            // console.log("555555555");
            biaowuPriceOpen.value = (NewsVue as any).totalResult && (NewsVue as any).totalResult[0] && (NewsVue as any).totalResult[0].str
          }
          // console.log("biaowuPriceOpen2",biaowuPriceOpen.value);
          //add 20230220 手机端未登录不展示价格
          if(!userInfo || !userInfo.id){
            biaowuPriceOpen.value ="0";
            // console.log("biaowuPriceOpen3",biaowuPriceOpen.value);

          }
        }
    )

    //  add shop car
    const {addShopCar} = useShopCar();

    function back() {
      router.go(-1);
    }

    return {
      addShopCar,
      userInfo,
      biaowuPriceOpen,
      back
    };
  },
});
