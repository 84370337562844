<template>
  <div>
    <transition-group tag="ul" name="list" class="post-ul">
      <li v-for="item in topicData.data" :key="item.id">
        <router-link
          :to="{ name: 'Personal', query: { uid: item.user && item.user.id } }"
          class="post-avatar"
        >
          <img :src="item.user && item.user.userImg" alt="" />
        </router-link>
        <div class="post-content">
          <router-link
            :to="{ name: 'TopicDetail', query: { topicId: item.id } }"
            class="title"
          >
            <span v-if="item.isVerify < 0" style="color: #df0024"
              >【拒签】</span
            >
            <span v-else-if="item.isVerify === 0" style="color: #df0024"
              >【待审核】</span
            >
            <span v-else>
              <span v-if="item.isBest > 0" style="color: #df0024"
                >【精华】</span
              >
              <span v-if="item.payTotalCredit > 0" style="color: #df0024"
                >【悬赏】</span
              >
              <span v-if="item.isTop > 0" style="color: #df0024">【置顶】</span>
            </span>
            <div v-html="item.title" style="display: inline"></div>
            <span class="pics-topic-list" v-if="item.picLinks">0</span>
          </router-link>
          <router-link
            :to="{ name: 'Personal', query: { uid: item.user && item.user.id } }"
            class="post-author"
          >
            {{ item.user && item.user.nickName }}
          </router-link>
          <router-link
            :to="{ name: 'TopicDetail', query: { topicId: item.id } }"
            class="post-article-content"
          >
            <span v-html="subString(item.summary)"></span>
          </router-link>
          <div class="post-info">
            <div class="date">{{ dateFormat(item.createTime) }}</div>
            <div class="view">
              <img
                src="@/assets/img/common/icon27.png"
                alt=""
              />
              <!-- src="https://www.bzwz.com/static/m/images/icon27.png" -->
              {{ item.clickCount }}
            </div>
            <div
              class="view-order-wapper"
              v-if="item.orderId && item.orderId > 0"
            >
              <router-link :to="{name:'NeiOrderDetail',query:{id:item.orderId}}" >
                <span class="view-order">查看订单</span>
              </router-link>
            </div>
          </div>
          <div
            class="post-recommend"
            v-if="item.likesList && item.likesList.length > 0"
          >
            <!-- <img src="https://www.bzwz.com/static/images/laud.png" alt="" /> -->
            <img src="@/assets/img/common/laud.png" alt="" />
            <div class="recommend-wapper">
              <router-link
                :to="{ name: 'Personal', query: { uid: i.id } }"
                class="post-author"
                v-for="i in item.likesList"
                :key="i.id"
              >
                {{ i.nickName }}&nbsp;&nbsp;
              </router-link>
            </div>
          </div>
        </div>
      </li>
    </transition-group>
    <transition-group
      tag="ul"
      v-if="topicData.loading"
      name="list"
      class="post-ul"
    >
      <li v-for="item in 5" :key="item" class="skeleton">
        <div class="post-avatar skeleton-bg"></div>
        <div class="post-content">
          <div class="title skeleton-bg"></div>

          <div class="post-author skeleton-bg"></div>

          <div class="post-article-content skeleton-bg"></div>

          <div class="post-info">
            <div class="date skeleton-bg"></div>
            <div class="view skeleton-bg"></div>
          </div>
          <div class="post-recommend skeleton-bg"></div>
        </div>
      </li>
    </transition-group>
  </div>
</template>


<script lang="ts">
import { defineComponent } from "vue";
import dateFormat from "../../utils/dateFormat";
export default defineComponent({
  name: "TopicItem",
  props: {
    topicData: Object,
  },
  setup() {
    type substr = string | undefined | null;
    const subString = function (str: substr) {
      if (str && str.length > 200) {
        return str.substr(0, 200) + "...";
      } else {
        return str;
      }
    };

    return {
      subString,
      dateFormat,
    };
  },
});
</script>


<style lang="scss" scoped>
/**帖子 start*/
.post-ul {
  width: 100%;
  padding: 5px 10px 0px 10px;
  box-sizing: border-box;
  li {
    width: 100%;
    padding: 15px 0 20px 50px;
    box-sizing: border-box;
    position: relative;
    border-bottom: 1px solid #f4f4f4;
    .post-avatar {
      position: absolute;
      left: 10px;
      top: 10px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }
  }
  .post-content {
    // width: 285px;
    text-align: left;
    padding-left: 5px;
    box-sizing: border-box;
    .title {
      display: block;
      font-size: 15px;
      color: #000;
      word-break: break-all;
      font-weight: 400;
      span {
        font-size: 14px;
        color: #df0024;
        font-weight: 700;
      }
    }
  }
}

.post-second-user {
  left: -15px;
}
.post-third-user {
  left: -30px;
}

.post-author {
  color: #20c2d6;
  font-size: 14px;
  display: block;
  margin-bottom: 10px;
}

.post-article-content {
  color: #666;
  font-size: 15px;
  word-break: break-all;
  display: block;
}

.post-info {
  overflow: hidden;
  margin-top: 15px;
  position: relative;
  .date {
    float: left;
    color: #999;
    font-size: 14px;
  }
  .class-tag {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 22.5px;
    background-color: #f8e7e7;
    border-radius: 10px;
    line-height: 22.5px;
    padding: 0 9px;

    font-size: 0;
    a {
      display: inline-block;
      color: #444444;
      font-size: 14px;
    }
  }
  .view {
    float: right;
    color: #999;
    font-size: 14px;
    display: flex;
    align-items: center;
    img {
      width: 25px;
      height: 15px;
      vertical-align: middle;
      margin-right: 5px;
    }
  }
}

.post-recommend {
  width: 100%;
  background-color: #f4f4f4;
  word-break: break-all;
  padding: 5px 10px;
  padding-left: 33.75px;
  box-sizing: border-box;
  line-height: 15px;
  position: relative;
  margin-top: 10px;
  img {
    width: 22.5px;
    height: 22.5px;
    position: absolute;
    top: 7.5px;
    left: 7.5px;
    z-index: 1;
  }
  a {
    font-size: 14px;
    color: #999;
    display: inline-block;
  }
}
.recommend-wapper {
  word-break: break-word;
}
.post-header {
  height: 50px;
  display: block;
  line-height: 50px;
  border-bottom: 1px solid #f4f4f4;
  .left {
    float: left;
    color: #666666;
    font-size: 16px;
    padding-left: 20px;
  }
  .post-header-right {
    float: right;
    img {
      position: relative;

      width: 22px;
      height: 22px;
      border-radius: 50%;
    }
  }
}

.pics-topic-list {
  background: url(https://shijiaoke.oss-cn-beijing.aliyuncs.com/Banner/post-icon-img20201202.png)
    no-repeat;
  width: 19px;
  height: 19px;
  display: inline-block;
  background-size: 15px 15px;
  color: transparent !important;
  background-position: 50% 22%;
}

/**帖子 end*/

// skeleton
@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  to {
    background-position: 0 50%;
  }
}

.skeleton {
  .post-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .title {
    height: 15px;
  }
  .post-author {
    margin-top: 10px;
    width: 80px;
    height: 10px;
  }
  .post-article-content {
    width: 100%;
    height: 30px;
  }
  .post-info {
    .date {
      width: 85px;
      height: 10px;
    }
    .view {
      width: 50px;
      height: 10px;
    }
  }
  .post-recommend {
    height: 10px;
  }
  .skeleton-bg {
    background: linear-gradient(
      90deg,
      hsla(0, 0%, 74.5%, 0.2) 25%,
      hsla(0, 0%, 50.6%, 0.24) 37%,
      hsla(0, 0%, 74.5%, 0.2) 63%
    );
    background-size: 400% 100%;
    animation: skeleton-loading 1.4s ease infinite;
  }
}


.view-order-wapper{
   float: left;
    margin-left: 30px;
    font-size: 0;
}
.view-order{
    display: inline-block;
    height: 22.5px;
    background-color: #f8e7e7;
    border-radius:10.013px;
    line-height: 24px;
    padding: 0 9px;
    color: #444444;
    font-size:13.988px;
    margin-right: 7.5px;
}


</style>